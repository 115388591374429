import axios from '@/common/axios'
import qs from 'qs'

export function addNeutralElectrodeCard (data) {
  return axios({
    method: 'post',
    url: '/production/neutralElectrodeCard/add',
    data: qs.stringify(data)
  })
}

export function deleteNeutralElectrodeCard (id) {
  return axios({
    method: 'delete',
    url: '/production/neutralElectrodeCard/delete/' + id
  })
}

export function updateNeutralElectrodeCard (data) {
  return axios({
    method: 'put',
    url: '/production/neutralElectrodeCard/update',
    data: qs.stringify(data)
  })
}

export function selectNeutralElectrodeCardInfo (id) {
  return axios({
    method: 'get',
    url: '/production/neutralElectrodeCard/info/' + id
  })
}

export function selectNeutralElectrodeCardList (query) {
  return axios({
    method: 'get',
    url: '/production/neutralElectrodeCard/list',
    params: query
  })
}

export function operateNeutralElectrodeCard (data) {
  return axios({
    method: 'put',
    url: '/production/neutralElectrodeCard/updateOperate',
    data: qs.stringify(data)
  })
}

export function reviewNeutralElectrodeCard (data) {
  return axios({
    method: 'put',
    url: '/production/neutralElectrodeCard/updateReview',
    data: qs.stringify(data)
  })
}
